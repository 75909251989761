import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Helmet from 'react-helmet'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { relative } from 'path'

const Blog = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges
  const featuredPost = posts[0].node
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1

  return (
    <Layout>
      <SEO />
      {!isFirstPage && (
        <Helmet>
          <title>{`Blog - Page ${currentPage} // ${config.siteTitle}`}</title>
        </Helmet>
      )}
      <section className="section">
        <div className="container">
          {isFirstPage ? (
            <CardList>
              <Card {...featuredPost} featured="true" />
              {posts.slice(1).map(({ node: post }) => (
                <Card key={post.id} {...post} />
              ))}
            </CardList>
          ) : (
            <CardList>
              {posts.map(({ node: post }) => (
                <Card key={post.id} {...post} />
              ))}
            </CardList>
          )}
        </div>
      </section>
      <section className="section">
        <Pagination context={pageContext} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`

export default Blog
